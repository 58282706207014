import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, navigate } from "gatsby";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { Row, Col, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "urql";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import "react-phone-number-input/style.css";

import imgLogo from "../../assets/image/logo.svg";

const SIGN_UP = `
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      result {
        workspaceId
        prospectId
      }
      errors {
        fullMessage
      }
    }
  }
`;

const subscribeToMailchimp = data => {
  let formData = new FormData();
  const [firstName, lastName] = data.fullName.split(" ", 2);

  formData.append("EMAIL", data.email);
  formData.append("FNAME", firstName);
  formData.append("LNAME", lastName);
  formData.append("BUNAME", data.businessName);

  return fetch(`${process.env.MAILCHIMP_SIGNUP_FORM}`, {
    method: "POST",
    mode: "no-cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "form-data",
    },
    body: formData,
  });
};

const SignUpPage = ({ location }) => {
  let defaultWorkEmail = location.state && location.state.workEmail;
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let [tokenRecaptcha, setTokenRecaptcha] = useState("");
  let [recaptchaMessage, setRecaptchaMessage] = useState(null);

  const [signUpResult, signUp] = useMutation(SIGN_UP);
  const { register, handleSubmit, control, errors } = useForm();
  const onSubmit = data => {
    if (tokenRecaptcha == "") {
      setRecaptchaMessage("You need to verify Recaptcha!");
      return;
    }
    subscribeToMailchimp(data)
      .then(() => {
        return signUp({
          input: {
            fullName: data.fullName,
            workEmail: data.email,
            phoneNumber: data.phoneNumber,
            businessName: data.businessName,
            timezone: timezone,
          },
        });
      })
      .then(res => {
        let result = res.data.signUp.result;
        navigate("/signup/done", {
          state: {
            prospectId: result.prospectId,
            workspaceId: result.workspaceId,
            fullName: data.fullName,
          },
        });
      })
      .catch(error => console.log(error));
  };

  return (
    <>
      <PageWrapper>
        <SEO title="Sign Up For Camelo"></SEO>
        <div className="sign-page bg-default-9">
          <header className="site-header bg-white">
            <Container fluid>
              <Row className="justify-content-center">
                <Col md="8" lg="5" xl="4">
                  <div className="brand text-center py-9">
                    <Link to="/">
                      <img src={imgLogo} alt="" />
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
          </header>

          <Container>
            <Row className="justify-content-center py-25">
              <Col lg="7">
                <div className="main-block">
                  <div className="form-title text-center">
                    <h2 className="title gr-text-2 mb-9">
                      Start a 30-day free trial
                    </h2>
                    <p className="gr-text-8 mb-13">
                      No credit card required, cancel anytime
                    </p>
                  </div>
                  <p className="text-danger">
                    {signUpResult.error && JSON.stringify(signUpResult.error)}
                  </p>
                  <div className="bg-white border rounded-10 px-8 py-8 shadow-1 mb-11">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <label
                          htmlFor="fullName"
                          className="gr-text-11 font-weight-bold text-blackish-blue"
                        >
                          First &amp; Last Name
                        </label>
                        <input
                          className="form-control gr-text-11 border"
                          type="text"
                          id="fullName"
                          name="fullName"
                          ref={register({ required: true })}
                          placeholder="i.e. John Doe"
                        />
                        {errors.fullName && (
                          <p className="text-danger pt-2">
                            <small>This field is required</small>
                          </p>
                        )}
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="email"
                          className="gr-text-11 font-weight-bold text-blackish-blue"
                        >
                          Work Email
                        </label>
                        <input
                          className="form-control gr-text-11 border"
                          type="email"
                          id="email"
                          name="email"
                          ref={register({ required: true })}
                          defaultValue={defaultWorkEmail}
                          placeholder="i.e. john@mail.com"
                        />
                        {errors.email && (
                          <p className="text-danger pt-2">
                            <small>This field is required</small>
                          </p>
                        )}
                      </div>

                      <div className="form-group text-left">
                        <label
                          htmlFor="businessName"
                          className="gr-text-9 font-weight-bold text-blackish-blue"
                        >
                          What is your business name?
                        </label>
                        <input
                          className="form-control gr-text-11 border"
                          type="text"
                          id="businessName"
                          name="businessName"
                          ref={register({ required: true })}
                          placeholder="i.e. CameloHQ"
                          required
                        />
                        {errors.businessName && (
                          <p className="text-danger pt-2">
                            <small>This field is required</small>
                          </p>
                        )}
                      </div>
                      <div className="form-group">
                        <label
                          htmlFor="phoneNumber"
                          className="gr-text-11 font-weight-bold text-blackish-blue"
                        >
                          Phone Number
                        </label>
                        <PhoneInputWithCountry
                          className="form-control"
                          name="phoneNumber"
                          control={control}
                          international
                          rules={{ required: true }}
                        />
                        {errors.phoneNumber && (
                          <p className="text-danger pt-2">
                            <small>This field is required</small>
                          </p>
                        )}
                      </div>
                      <div className="form-group">
                        <ReCAPTCHA
                          sitekey={`${process.env.RECAPTCHA_KEY}`}
                          onChange={token => {
                            setTokenRecaptcha(token);
                            setRecaptchaMessage(null);
                          }}
                          onErrored={error => console.log("error", error)}
                          type="image"
                        />
                        {recaptchaMessage && (
                          <p className="text-danger pt-2">
                            <small>{recaptchaMessage}</small>
                          </p>
                        )}
                      </div>

                      <div className="form-group  mb-7">
                        <label
                          htmlFor="terms-check"
                          className="gr-check-input mb-7 d-flex"
                        >
                          <p className="gr-text-11 mb-0">
                            By signing up you accept the{" "}
                            <a href="/legal" target="_blank">
                              Terms &amp; Conditions
                            </a>
                          </p>
                        </label>
                      </div>
                      <div className="form-group button-block mb-2">
                        <input
                          type="submit"
                          value={
                            signUpResult.fetching
                              ? "Creating your account..."
                              : "Create an account"
                          }
                          disabled={signUpResult.fetching}
                          className="form-btn btn btn-primary gr-hover-y w-100"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </PageWrapper>
    </>
  );
};
export default SignUpPage;
